
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import KTFacePhoto from "@/views/face/FacePhoto.vue";

export default defineComponent({
  name: "account-settings",
  components: {
    KTFacePhoto
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        real_name: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur"
          }
        ],
        id_card_no: [
          {
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    setFacePhoto(data) {
      (this.ruleForm as any).face_photo = data;
    },
    handleFacePhoto() {
      (this.$refs["KTFacePhoto"] as any).initData();
    },
    submitForm() {
      (this.$refs["ruleForm"] as any).validate((valid) => {
        if (valid) {
          // Activate indicator
          (this.$refs["submitButton"] as any).setAttribute(
            "data-kt-indicator",
            "on"
          );
          ApiService.post(
            "MemberProfile/RealNameEdit",
            this.ruleForm as AxiosRequestConfig
          )
            .then(({ data }) => {
              if (data.success == true) {
                Bus.$emit("getUserDetail");
                this.$router.push("/profile/certificationView");
                Toast.success(data.message);
              } else {
                Toast.warning(data.message);
              }
            })
            .catch((error) => {
              Toast.error(error.message);
            })
            .finally(() => {
              (this.$refs["submitButton"] as any).removeAttribute(
                "data-kt-indicator"
              );
            });
        }
      });
    },
    initBus() {
      Bus.$on("setFacePhoto", (data) => {
        this.setFacePhoto(data);
      });
    }
  },
  beforeUnmount() {
    Bus.$off("setFacePhoto");
  },
  mounted() {
    setCurrentPageBreadcrumbs("实名认证", ["账户"]);
    this.initBus();
  }
});
